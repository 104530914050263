import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { Link } from 'gatsby'
const siteTitle = 'Stephen Kempin - Front End Developer'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Error 404 - {siteTitle}</title>
      <meta
        name="description"
        content="Error 404 - Ppge not found. Sorry that page cannot be found."
      />
      <link rel="canonical" href="https://www.stephenkempin.co.uk/" />
      <meta property="og:title" content={siteTitle} />
      <meta
        property="og:description"
        content="Stephen Kempin is a Bristol based Front End Developer specialising in HTML5, JS, React, React Native and Wordpress development."
      />
      <meta property="og:url" content="https://www.stephenkempin.co.uk/" />
      <meta property="og:image" content="/assets/images/sk-uk-icon.png" />
      <html lang="en" />
    </Helmet>
    <section className="main special">
      <div className="grid-wrapper">
        <div className="col-3" />
        <div className="col-6">
          <header className="major">
            <h1>Error 404 - Page Not Found</h1>
          </header>
          <p>
            Sorry, that page doesn't exist.
            <br />
            <Link to="/">Please navigate back to the homepage.</Link>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
